import React, { useState } from "react";
import { postData, patchData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { toast } from "react-toastify";
import { useNotification } from "../../../hooks/useNotification";
import { Loader } from "../../../component/Loader/Loader.component";

const SMSNotification = ({
  details,
  isLoading,
  refetch,
}: {
  details: any;
  isLoading: any;
  refetch: any;
}) => {
  const pushData = details?.push_notification;
  const smsData = details?.sms;
  const emailData = details?.email;

  const togglePush = async () => {
    const reqBody = {
      push_notification: pushData,
      sms: smsData ? false : true,
      email: emailData,
    };

    try {
      const res = await patchData(
        `${CONFIG.BASE_URL2}${apiEndpoints.UPDATE_NOTIFICATION}`,
        reqBody
      );
      refetch();
      toast.success(res.message);
    } catch (error) {
      toast.error("Error Updating SMS Notification, Try again");
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="w-[80%]">
          <p className="text-[#0B1E4B] mt-[16px]">SMS Notifications</p>
          <p className="font-thin text-[#7B7B7B] text-[14px]">
            Recieve SMS notifications for your activities on your workspace
          </p>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div
            onClick={togglePush}
            className={`${
              smsData ? "bg-[#2054D2]" : "bg-[#F3F5FC]"
            } cursor-pointer w-[45px] rounded-[1rem] h-[24px] border border-[#C5D4F6] relative`}
          >
            <div
              className={`w-[19.6px] absolute top-[50%] transition translate-y-[-50%] ${
                smsData ? "left-[50%]" : "left-[2%]"
              }  h-[19.6px] bg-white rounded-[50%] shadow-md`}
            ></div>
          </div>
        )}
      </div>
    </>
  );
};

export default SMSNotification;
