import CustomInputField from "../../../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { UnregisteredStepTwoForm } from "../../../../interfaces/profileSetUp";
import {
  Loader,
  SpinLoader,
} from "../../../../component/Loader/Loader.component";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomTextArea from "../../../../component/CustomHTMLElements/CustomTextArea";
import { convertToBase64 } from "../../../../helpers/convertToBase64";
import { toast } from "react-toastify";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { postData, putData } from "../../../../apis/apiMethods";
import { errorHandler } from "../../../../helpers/errorHandler";
import { useQueryClient } from "react-query";
import { ReactComponent as AttachIcon } from "../../../../assets/images/svg/attach-icon.svg";
import InfoIcon from "../../../../assets/images/svg/blue-info-icon.svg";
import { identificationTypes } from "../../../../helpers/json";
import { getFromLocalStorage } from "../../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../../helpers/localStorageKeys";

const schema = yup.object().shape({
  description: yupValidators.genericRequired({
    min: 1,
    max: 150,
  }),
  tradeName: yupValidators.businessName,
  moi: yup.string().required("Means of identification is required"),
  poa: yup.string().required("Proof of address is required"),
  identification: yup.string().required(),
});

const ProfileStepTwo = ({ merchantDetails }: { merchantDetails: any }) => {
  const [loading, setLoading] = useState(false);
  const [identification, setIdentification] = useState<any>({});
  const [loadingFile, setLoadingFile] = useState<any>({
    DRIVER_LICENSE: false,
    poa: false,
  });
  const [poa, setPoa] = useState<any>({});
  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UnregisteredStepTwoForm>({
    resolver: yupResolver(schema),
  });

  const moi = watch("moi");
  const onFileChange = async ({
    e,
    fileType,
  }: {
    e: any;
    fileType: string;
  }) => {
    setLoadingFile({ ...loadingFile, [fileType]: true });
    if (e?.target?.files[0]?.name) {
      const fileAcceptType = e.target.files[0].type;
      const fileName = e.target.files[0].name;
      const isValidFile =
        fileAcceptType.startsWith("image/png") ||
        fileAcceptType.startsWith("image/jpg") ||
        fileAcceptType.startsWith("image/jpeg") ||
        fileAcceptType.startsWith("application/pdf");
      if (!isValidFile) {
        setLoadingFile({ ...loadingFile, [fileType]: false });
        toast.error(
          "Invalid file extension. Kindly upload a pdf,word,png,jpeg or jpg file"
        );
        return (e.target.value = null);
      }
      if (e.target.files[0].size > 10000000) {
        setLoadingFile({ ...loadingFile, [fileType]: false });
        toast.error(
          "File size is too large. Max file size is 10mb. Kindly reupload another Document"
        );
        return (e.target.value = null);
      }
      const fileBase64 = await convertToBase64(e.target.files[0]);

      const reqBody = {
        user_id: userId,
        photo: fileBase64,
      };

      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res: any = await postData(
          `${CONFIG.BASE_URL1}${apiEndpoints.UPLOAD_IMAGE}`,
          reqBody
        );

        if (fileType === "poa") {
          setPoa({
            file: res?.data?.filename,
            fileName,
          });
          setValue("poa", res?.data?.filename);
        }
        if (fileType === moi) {
          setIdentification({
            file: res?.data?.filename,
            type: "DRIVER_LICENSE",
            fileName,
          });
          setValue("identification", res?.data?.filename);
        }
      } catch (error) {
        toast.error(errorHandler(error));
      }
    }
    setLoadingFile({ ...loadingFile, [fileType]: false });
  };

  useEffect(() => {
    if (merchantDetails?.attributes?.unregisteredDocument) {
      setPoa({
        fileName:
          merchantDetails?.attributes?.unregisteredDocument?.proof_of_address,
      });
      setValue(
        "moi",
        merchantDetails?.attributes?.unregisteredDocument?.means_of_id?.type
      );
      setIdentification({
        fileName:
          merchantDetails?.attributes?.unregisteredDocument?.means_of_id?.file,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  const onSubmit = async ({
    tradeName,
    description,
  }: {
    tradeName: string;
    description: string;
  }) => {
    setLoading(true);
    const reqBody = {
      proof_of_address: poa.file,
      means_of_id: {
        file: identification.file,
        type: identification.type,
      },
      name: tradeName,
      desc: description,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await putData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_UNREG_DETAILS}`,
        reqBody
      );
      queryClient.invalidateQueries("getMerchantDetails");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Business Details
          </h3>

          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[26px]">
          This is the details of the owner of this business
        </p>

        <CustomInputField
          type="text"
          maxLength={128}
          label="Proposed Business Name"
          errors={errors.tradeName}
          {...register("tradeName")}
          placeholder="Proposed Business Name"
          // readOnly={merchantDetails?.attributes?.unregisteredDocument?.name}
          defaultValue={
            merchantDetails?.attributes?.unregisteredDocument?.name ||
            merchantDetails?.merchant?.name
          }
          readOnly
        />

        <CustomTextArea
          maxLength={150}
          label="Business description"
          errors={errors.description}
          {...register("description")}
          placeholder="Tell us something about your business"
          bottomLabel="A maximum of 150 characters"
          readOnly={merchantDetails?.attributes?.unregisteredDocument?.desc}
          defaultValue={merchantDetails?.attributes?.unregisteredDocument?.desc}
        />
        <div className="border border-[#C5D4F6] rounded-lg p-4 bg-[#F3F5FC] mt-4 flex items-start">
          <img src={InfoIcon} alt="" />
          <div className="ml-2">
            <h1 className="text-[14px] lg:leading-[14px] font-[400]">
              Please Note
            </h1>
            <p className="text-[14px] lg:leading-[21px] font-[300] pt-2">
              Aella reserve the right to deny a proposed business name where it
              appears similar to an existing registered business name, capable
              of misleading the public, offensive, undesirable or contrary to
              public (missing). This includes but not limited to words that
              promote prostitution, gambling, cultism or any form of crime. We
              recommend that you register your business with the CAC to enjoy
              the full benefits of a Merchant name with Aella
            </p>
          </div>
        </div>
        <h2 className="text-[16px] lg:leading-[16px] font-[300] tracking-[0.48px] mt-10 text-aellaGray">
          Required Documents:
        </h2>
        <div className="mt-[33px]">
          <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
            Proof of Address (Utility Bill, Bank Statement)
          </li>

          <div className="flex flex-col w-full pt-4">
            <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
              Upload document
            </label>

            <label
              htmlFor="poa"
              className={` ${
                errors?.poa ? "border-[#EB5757]" : "border-aellaLightGray"
              } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
            >
              <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                {poa?.fileName ? poa?.fileName : "Attach File"}
              </p>

              <input
                type="file"
                id="poa"
                className="hidden"
                accept="application/pdf, image/*"
                {...register("poa")}
                disabled={
                  merchantDetails?.attributes?.unregisteredDocument
                    ?.proof_of_address || loadingFile.poa
                }
                onChange={(e: any) => {
                  onFileChange({
                    e,
                    fileType: "poa",
                  });
                }}
              />
              {loadingFile.poa ? <SpinLoader /> : <AttachIcon />}
            </label>
          </div>
        </div>
        <li className="text-[16px] lg:leading-[19px] mt-8 font-[300] tracking-[0.03px] w">
          Valid means of Identification
        </li>
        <div className="flex flex-col lg:flex-row pt-4 ">
          <div className=" flex flex-col lg:flex-row items-center lg:pr-6 w-full">
            <div className="w-full">
              <label className="text-aellaGray text-[14px] lg:leading-[16px] tracking-[0.03px] font-[300] mb-2">
                Select means of identification{" "}
              </label>
              <select
                className={` ${
                  errors?.moi ? "border-[#EB5757]" : "border-aellaLightGray"
                } bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white `}
                {...register("moi")}
                disabled={
                  merchantDetails?.attributes?.unregisteredDocument?.means_of_id
                    ?.type
                }
                value={
                  merchantDetails?.attributes?.unregisteredDocument?.means_of_id
                    ?.type
                }
              >
                <option
                  value=""
                  className="text-[#DADADA] text-[16px] lg:leading-[24px] font-[100]"
                >
                  {/* Select Identification */}
                </option>
                {identificationTypes?.map((data: any, _id: any) => {
                  const { value, name } = data;
                  return (
                    <option value={value} key={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
              Upload document
            </label>
            <label
              htmlFor="identification"
              className={` ${
                errors?.identification
                  ? "border-[#EB5757]"
                  : "border-aellaLightGray"
              } inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
            >
              <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                {identification?.fileName
                  ? identification?.fileName
                  : "Attach File"}
              </p>
              <input
                type="file"
                id="identification"
                accept="application/pdf, image/*"
                className="hidden"
                onChange={(e: any) => {
                  onFileChange({
                    e,
                    fileType: moi,
                  });
                }}
                disabled={
                  merchantDetails?.attributes?.unregisteredDocument?.means_of_id
                    ?.file ||
                  loadingFile[moi] ||
                  !moi
                }
              />
              {loadingFile[moi] ? <SpinLoader /> : <AttachIcon />}
            </label>
          </div>
        </div>
        <div className="flex justify-between xxl:mt-7 mt-10">
          <div></div>
          <div></div>
          <div></div>

          {!(
            merchantDetails?.attributes?.profile?.upgrade_stage === "Detail" ||
            merchantDetails?.attributes?.profile?.upgrade_stage === "Address"
          ) && (
            <button
              type="submit"
              className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex justify-center cursor-pointer"
              disabled={loading}
            >
              Save and Continue
              {loading && <Loader />}
            </button>
          )}
        </div>
      </form>
    </>
  );
};
export default ProfileStepTwo;
