import React, { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import WithdrawalAccountCard from "../../../component/Cards/WithdrawalAccountCard";
import { useMerchantWithdrawalAccountDetails } from "../../../hooks/useMerchantWithdrawalAccountDetails.tsx";
import { ReactComponent as AddIcon } from "../../../assets/images/svg/add-icon.svg";
import SuccessAnimation from "../../../assets/files/Stars.json";
import PinInput from "react-pin-input";
import { Loader, SpinLoader } from "../../../component/Loader/Loader.component";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
import { useAllWithdrawalBanks } from "../../../hooks/useAllWithdrawalBanks";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { currencyFormat } from "../../../helpers/formatter";
import { DetailObjectType } from "../ProfileSettings";
import { useAuth } from "../../../context/auth";

interface TGenerateAccountModals {
  modalType: string;
  setModalType: (newState: string) => void;
  setDetail: (newState: any) => void;
  setIsOpen: (newState: boolean) => void;
  onSubmitTrnxPin: (newState: string) => void;
  submitTransferPin: (newState: string) => void;
  loadingTrnxPin: boolean;
  withdrawalAmount: number;
  toServiceName?: string;
  transferAmount?: number;
  detail?: DetailObjectType;
  transferMessage?: string;
  trnxMessage?: string;
}

const AddAccountModal: React.FC<TGenerateAccountModals> = ({
  modalType,
  setModalType,
  setDetail,
  setIsOpen,
  onSubmitTrnxPin,
  submitTransferPin,
  loadingTrnxPin,
  withdrawalAmount,
  toServiceName,
  transferAmount,
  detail,
  transferMessage,
  trnxMessage,
}) => {
  const { details } = useAuth();
  const [trnxPin, setTrnxPin] = useState("");
  const [transferPin, setTransferPin] = useState("");
  const [accountName, setAccountName] = useState(detail?.account_name);
  const [accountDetailsLoading, setAccountDetailsLoading] = useState(false);
  const [withdrawalAccntLoader, setWithdrawalAccntLoader] = useState(false);
  const [bankId, setBankId] = useState("");
  const {
    data: withDrawalBankAccountDetails,
    isLoading: withDrawalAccountDetailsLoading,
  } = useMerchantWithdrawalAccountDetails({
    merchantId: details?.merchant_id,
  });
  const { data: bankData } = useAllWithdrawalBanks();
  const onSubmitPin = () => {
    onSubmitTrnxPin(trnxPin);
  };
  const onSubmitTransferPin = () => {
    submitTransferPin(transferPin);
  };
  const { register, watch } = useForm();
  const queryClient = useQueryClient();
  const accountNumber = watch("accountNumber");

  const validateBank = async (id: string) => {
    setAccountDetailsLoading(true);
    setBankId(id);
    const reqBody = {
      account_number: accountNumber,
      bank_id: id,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.VALIDATE_BANKS}`,
        reqBody
      );
      setAccountName(res.data.account_name);
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setAccountDetailsLoading(false);
  };

  const onSubmitWithdrawalAccount = async () => {
    setWithdrawalAccntLoader(true);
    const reqBody = {
      bank_id: bankId,
      account_number: accountNumber,
      account_name: accountName,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.ADD_WITHDRAWAL_ACCOUNT}`,
        reqBody
      );
      queryClient.invalidateQueries("merchantWithdrawalAccountDetails");
      setModalType("");
      toast.success(errorHandler("Beneficiary Added Successfully"));
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setWithdrawalAccntLoader(false);
  };

  if (withDrawalAccountDetailsLoading) {
    return (
      <div className="items-center">
        <SpinLoader />
      </div>
    );
  }

  return (
    <>
      <div className="absolute justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none">
        <div className="relative  mt-2 mb-40 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setModalType("");
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          {/*content*/}
          <div className="border-0 bg-white rounded-lg p-10 flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none ">
            {(() => {
              switch (modalType) {
                case "step1":
                  return (
                    <>
                      {/*header*/}
                      <h1 className="leading-6 font-[400] text-[18px] tracking[0.4px] ">
                        Select Bank Account
                      </h1>
                      <p className="text-[14px] leading-[18px] font-[200] text-[#232323] pt-[5px]">
                        Select the bank account you would like to transfer to.
                      </p>
                      <div className="mt-[30px]">
                        {withDrawalBankAccountDetails?.map(
                          (accountDetail: any) => {
                            return (
                              <div
                                onClick={() => {
                                  setIsOpen(true);
                                  setDetail(accountDetail);
                                  setModalType("");
                                }}
                              >
                                <WithdrawalAccountCard
                                  details={accountDetail}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                      <div className="flex justify-center">
                        <button
                          className=" px-[16px] py-[14px] flex rounded-[6px] mt-10 bg-white border border-aellaBlue rounded-r text-aellaBlue disabled:bg-colors-blue5 items-center transition ease-in-out duration-300  font-normal text-[14px]"
                          type="submit"
                          onClick={() => setModalType("step3")}
                        >
                          <span className="mr-2">
                            <AddIcon />
                          </span>
                          Add New Bank Account
                        </button>
                      </div>
                    </>
                  );
                case "step2":
                  return (
                    <>
                      {/*header*/}
                      <h1 className="leading-6 font-[400] text-[18px] tracking[0.4px] ">
                        Enter your transaction pin
                      </h1>
                      <p className="text-[14px] leading-[18px] font-[200] text-[#232323] pt-[28px] pb-[22px]">
                        Enter Pin
                      </p>
                      <PinInput
                        length={4}
                        placeholder="0"
                        secret
                        initialValue=""
                        onChange={(value, index) => setTrnxPin(value)}
                        type="numeric"
                        inputMode="number"
                        inputStyle={{
                          border: "1px solid #2054D2",
                          marginRight: "5px",
                          paddingRight: "0px",
                          paddingLeft: "0px",
                          fontWeight: 100,
                        }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                      />{" "}
                      <div className=" xxl:mt-7 mt-7 mb-28 w-[290px]  lg:mb-0 ">
                        <button
                          type="submit"
                          className="bg-aellaBlue disabled:opacity-75 rounded w-[225px] text-white p-[15.5px] items-center flex justify-center cursor-pointer"
                          disabled={loadingTrnxPin}
                          onClick={() => onSubmitPin()}
                        >
                          Make Transfer
                          {loadingTrnxPin && <Loader />}
                        </button>
                      </div>
                    </>
                  );
                case "step3":
                  return (
                    <>
                      <h1 className="leading-6 font-[400] text-[18px] tracking[0.4px] mb-[32px]">
                        Select Bank Account
                      </h1>

                      <CustomInputField
                        type="number"
                        maxLength={10}
                        label="Account Number"
                        {...register("accountNumber")}
                      />

                      <CustomSelectDropdown
                        label="Bank"
                        {...register("bankId")}
                        readOnly={!accountNumber}
                        onChange={(e) => validateBank(e.target.value)}
                      >
                        <option value="">Select Bank</option>
                        {bankData?.map((data: any) => {
                          const { id, name } = data;
                          return <option value={id}>{name}</option>;
                        })}
                      </CustomSelectDropdown>
                      {accountDetailsLoading && <SpinLoader />}
                      {accountName && (
                        <CustomInputField
                          type="text"
                          maxLength={10}
                          label="Account Name"
                          defaultValue={accountName}
                        />
                      )}
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className="bg-white disabled:opacity-75 rounded w-[225px] text-aellaBlue border border-aellaBlue  p-[15.5px] items-center flex justify-center cursor-pointer"
                          disabled={
                            !accountNumber ||
                            !bankId ||
                            !accountName ||
                            withdrawalAccntLoader
                          }
                          onClick={() => onSubmitWithdrawalAccount()}
                        >
                          Add Bank Account
                          {withdrawalAccntLoader && <Loader />}
                        </button>
                      </div>
                    </>
                  );

                case "step4":
                  return (
                    <>
                      <div className="flex h-full items-center flex-col w-full">
                        <div className="">
                          <Player
                            autoplay
                            controls
                            loop
                            src={SuccessAnimation}
                            style={{
                              width: "120px",
                              height: "120px",
                              position: "absolute",
                            }}
                          />
                          <h1 className="text-[26px] lg:leading-[100%] font-[300] text-aellaBlue tracking-[0.4] relative mt-12">
                            Awesome
                          </h1>
                        </div>

                        <div className="text-center mt-[64px]">
                          <h1 className="text-[20px] lg:leading-[24px] font-[300] capitalize">
                            {trnxMessage}
                          </h1>

                          <p className="font-thin text-sm text-center pt-4">
                            Your transfer of{" "}
                            <span className="font-[400] pl-1">
                              {" "}
                              {currencyFormat(withdrawalAmount)}
                            </span>{" "}
                            <br></br>to{" "}
                            <span className="font-[400]">{accountName}</span>{" "}
                            was successful.
                          </p>
                        </div>
                      </div>
                    </>
                  );
                case "step5":
                  return (
                    <>
                      {/*header*/}
                      <h1 className="leading-6 font-[400] text-[18px] tracking[0.4px] ">
                        Enter your transaction pin
                      </h1>
                      <p className="text-[14px] leading-[18px] font-[200] text-[#232323] pt-[28px] pb-[22px]">
                        Enter Pin
                      </p>
                      <PinInput
                        length={4}
                        placeholder="0"
                        secret
                        initialValue=""
                        onChange={(value, index) => setTransferPin(value)}
                        type="numeric"
                        inputMode="number"
                        inputStyle={{
                          border: "1px solid #2054D2",
                          marginRight: "5px",
                          paddingRight: "0px",
                          paddingLeft: "0px",
                          fontWeight: 100,
                        }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                      />{" "}
                      <div className=" xxl:mt-7 mt-7 mb-28 w-[290px]  lg:mb-0 ">
                        <button
                          type="submit"
                          className="bg-aellaBlue disabled:opacity-75 rounded w-[225px] text-white p-[15.5px] items-center flex justify-center cursor-pointer"
                          disabled={loadingTrnxPin}
                          onClick={() => onSubmitTransferPin()}
                        >
                          Make Transfer
                          {loadingTrnxPin && <Loader />}
                        </button>
                      </div>
                    </>
                  );
                case "step6":
                  return (
                    <>
                      <div className="flex h-full items-center flex-col w-full">
                        <div className="">
                          <Player
                            autoplay
                            controls
                            loop
                            src={SuccessAnimation}
                            style={{
                              width: "120px",
                              height: "120px",
                              position: "absolute",
                            }}
                          />
                          <h1 className="text-[26px] lg:leading-[100%] font-[300] text-aellaBlue tracking-[0.4] relative mt-12">
                            Awesome
                          </h1>
                        </div>

                        <div className="text-center mt-[64px]">
                          <h1 className="text-[24px] lg:leading-[19px] font-[300] capitalize">
                            {transferMessage}
                          </h1>
                          <p className="font-thin text-sm text-center pt-4">
                            Your transfer of{" "}
                            <span className="font-[400] pl-1">
                              {" "}
                              {currencyFormat(transferAmount)}
                            </span>{" "}
                            to your
                            <span className="font-[400] pl-1">
                              {toServiceName}
                            </span>{" "}
                            wallet was successful
                          </p>
                          {/* <div className="text-center">
                            <button
                              type="submit"
                              className="bg-aellaBlue items-center text-center disabled:opacity-75 rounded  text-white p-[15.5px] mt-[20px] flex justify-center cursor-pointer"
                              onClick={() => {
                                setShowTrnxHistory(true);
                                setModalType("");
                              }}
                            >
                              View Details
                              {loadingTrnxPin && <Loader />}
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
      <div className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"></div>
    </>
  );
};
export default AddAccountModal;
