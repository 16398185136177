import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import { useFilterTeams } from "../../../hooks/useFilterTeams";

const SuperAdminList = () => {
  const { data, isLoading } = useFilterTeams({
    type: "SuperAdmin",
    size: "15",
  });

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
        </div>
      ) : (
        <div className="w-[80%] border border-[#EDEFF5]">
          <div className="bg-[#F3F5FC] flex p-[14px]">
            <p className="w-[25%]  text-[#0B1E4B]">Full Name</p>
            <p className="w-[25%] text-[#0B1E4B] ">Email Address</p>
            <p className="w-[25%] text-[#0B1E4B] ">Gender</p>
            <p className="w-[25%] text-[#0B1E4B] ">Role</p>
          </div>

          <div className="flex flex-col gap-[16px] my-[16px]">
            {data?.data?.map((each) => (
              <div key={each?.id} className="flex p-[14px] ">
                <div className="w-[25%] text-[#3C4249] font-thin">
                  {each?.user?.first_name} {each?.user?.last_name}
                </div>
                <div className="w-[25%] text-[#3C4249] font-thin">
                  <p className="w-[95%] truncate">{each?.user?.email}</p>
                </div>
                <div className="w-[25%] text-[#3C4249] font-thin">
                  {each?.user?.gender ? each?.user?.gender : "_"}
                </div>
                <div className="w-[25%] text-[#3C4249] font-thin flex gap-[14px] items-center">
                  <p>{each?.type}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SuperAdminList;
