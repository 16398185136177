import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getFilteredTeam = async ({ queryKey }: { queryKey: any }) => {
  const { type, size } = queryKey[1];

  let queryParams = `?type=${type}&per_page=${size}`;

  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ADMINS_LIST}${queryParams}`
  );
  return res;
};

export const useFilterTeams = ({
  type,
  size,
}: {
  type: string;
  size: string;
}) => {
  return useQuery(["getAllFilteredMembers", { type, size }], getFilteredTeam, {
    retry: 1,
    refetchOnWindowFocus: false,
  });
};
