import React, { useState } from "react";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import CustomTextArea from "../../../component/CustomHTMLElements/CustomTextArea";
import { ReactComponent as FileIcon } from "../../../assets/images/svg/file-icon.svg";
import SocialLinkInput from "../Components/SocialLinkInput";
import { ReactComponent as Instagram } from "../../../assets/images/svg/instagram-icon.svg";
import { ReactComponent as Twitter } from "../../../assets/images/svg/twitter-icon.svg";
import { ReactComponent as Facebook } from "../../../assets/images/svg/facebook-icon.svg";
import { useForm } from "react-hook-form";
import { patchData, postData, putData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { toast } from "react-toastify";
import { errorHandler } from "../../../helpers/errorHandler";
import { Loader } from "../../../component/Loader/Loader.component";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { getFirstLetter } from "../../../helpers/capitalizeFirstLetter";

import { getFirstLetter } from "../../../helpers/capitalizeFirstLetter";

interface UpdateLogo {
  desc: string;
  logo: string;
}

interface AddSocialLink {
  instagram?: string;
  facebook?: string;
  twitter?: string;
}

const BusinessForm = ({
  userData,
  userDocument,
  refetchProfile,
  refetchDocument,
}: {
  userData: any;
  userDocument: any;
  refetchProfile: any;
  refetchDocument: any;
}) => {
  const [currentFile, setCurrentFile] = useState<any>("");
  const [fileName, setFileName] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("undefined");
  const [ext, setExt] = useState("");

  const regexInstagram =
    /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/im;

  const regexTwitter =
    /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com|twitr.am)\/([a-zA-Z0-9_]+)/im;

  const regexFacebook =
    /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com|fb.am)\/([a-zA-Z0-9_]+)/im;

  // const regexLinkdIn =
  //   /(?:(?:http|https):\/\/)?(?:www.)?(?:linkedin.com|lnkd.in)\/(?:in\/)?([a-zA-Z0-9_-]+)/im;

  const schema = yup.object().shape({
    profile: yup.mixed(),
    instagram: yup.string().nullable().matches(regexInstagram, {
      message: "Enter a valid instagram url",
      excludeEmptyString: true,
    }),
    facebook: yup.string().nullable().matches(regexFacebook, {
      message: "Enter a valid facebook url",
      excludeEmptyString: true,
    }),
    twitter: yup.string().nullable().matches(regexTwitter, {
      message: "Enter a valid twitter url",
      excludeEmptyString: true,
    }),
  });

  const convertToBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      //@ts-ignore
      reader.onload = () => resolve(reader.result?.split("base64,")[1]);
      reader.onerror = (error) => reject(error);
    });

  const handleFileUpload = async (e: any) => {
    // const file = e.target.files[0];
    const files = e.target.files[0];

    const fileAcceptType = e.target.files[0].type;
    const isValidFile =
      fileAcceptType.startsWith("image/png") ||
      fileAcceptType.startsWith("image/jpg") ||
      fileAcceptType.startsWith("image/jpeg") ||
      fileAcceptType.startsWith("image/svg");

    if (!isValidFile) {
      toast.error(
        "Invalid file extension. Kindly upload a png, jpeg or svg file"
      );
      return (e.target.value = null);
    }
    if (e.target.files[0].size > 3000000) {
      toast.error("File size is too large. Max file allowed is 3mb");
      return (e.target.value = null);
    }

    const base64 = await convertToBase64(files);
    const extension = files.name.split(".").pop();
    setCurrentFile(e.target.files[0]?.name);
    setFile(URL.createObjectURL(e.target.files[0]));

    setExt(extension);
    setFileName(base64);

    const reqBody = {
      user_id: userData?.merchant_id,
      photo: base64,
    };

    try {
      const res = await postData(
        `${CONFIG.BASE_URL1}${apiEndpoints.UPLOAD_IMAGE}`,
        reqBody
      );

      const reqBody2 = {
        logo: res?.data?.filename,
        desc: "",
      };

      try {
        const res = await patchData(
          `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_DETAILS}`,
          reqBody2
        );

        toast.success(res.message);
      } catch (error) {
        toast.error(errorHandler(error));
      }
    } catch (error) {
      toast.error(errorHandler(error));
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateLogo>({
    resolver: yupResolver(schema),
  });

  const {
    register: newRegister,
    handleSubmit: socialSubmit,
    formState: { errors: newError },
  } = useForm<AddSocialLink>({
    resolver: yupResolver(schema),
  });

  const submitSocialLinkForm = async (data: AddSocialLink) => {
    const reqBody = {
      desc: "",
      logo: "",
      links: [
        {
          url: data?.instagram || "",
          type: "Instagram",
        },

        {
          url: data?.twitter || "",
          type: "Twitter",
        },

        {
          url: data?.facebook || "",
          type: "Facebook",
        },
      ],
    };

    const reqBody2 = {
      desc: "",
      logo: "",
      links: [
        {
          url: data?.instagram || "",
          type: "Instagram",
        },

        {
          url: data?.twitter || "",
          type: "Twitter",
        },

        {
          url: data?.facebook || "",
          type: "Facebook",
        },
      ],
    };

    const reqBody3 = {
      links: [],
    };

    //Function to add a link if the URL is not empty
    const addLink = (url, type) => {
      if (url) {
        reqBody3.links.push({ url, type });
      }
    };

    addLink(data?.instagram, "Instagram");
    addLink(data?.twitter, "Twitter");
    addLink(data?.facebook, "Facebook");

    // console.log("dlkasd", Object.keys(userData?.links).length, reqBody3);

    if (Object.keys(userData?.links).length === 0) {
      try {
        const res = await postData(
          `${CONFIG.BASE_URL2}${apiEndpoints.ADD_BULK_SOCIAL_MEDIA_LINKS}`,
          reqBody3
        );

        toast.success(res.message);
      } catch (error) {
        toast.error(errorHandler(error));
      }
    } else if (Object.keys(userData?.links).length < 3) {
      try {
        const res = await patchData(
          `${CONFIG.BASE_URL2}${apiEndpoints.ADD_BULK_SOCIAL_MEDIA_LINKS}`,
          reqBody3
        );

        toast.success(res.message);
      } catch (error) {
        toast.error(errorHandler(error));
      }
    } else {
      if (reqBody3.links.length < 3) {
        try {
          const res = await patchData(
            `${CONFIG.BASE_URL2}${apiEndpoints.ADD_BULK_SOCIAL_MEDIA_LINKS}`,
            reqBody3
          );

          toast.success(res.message);
        } catch (error) {
          toast.error(errorHandler(error));
        }
      } else {
        try {
          const res = await patchData(
            `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_DETAILS}`,
            reqBody
          );

          toast.success(res.message);
        } catch (error) {
          toast.error(errorHandler(error));
        }
      }
    }
  };

  const submitForm = async (data: UpdateLogo) => {
    setLoading(true);

    const reqBody = {
      desc: data?.desc,
      logo: "",
    };

    try {
      const res = await patchData(
        `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_DETAILS}`,
        reqBody
      );

      toast.success(res.message);
      refetchProfile();
    } catch (error) {
      toast.error(errorHandler(error));
    }

    setLoading(false);
  };

  return (
    <div className="xl:w-[65%]">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="flex gap-[25px] lg:flex-row xxs:flex-col">
          <div className="h-[78px] w-[78px] rounded-[50%] bg-[#2054D2] flex justify-between items-center overflow-hidden">
            {userData?.merchant?.logo === null ? (
              <div className="w-full h-full flex justify-center items-center">
                <p className="uppercase text-white text-[37px] font-thin">
                  {getFirstLetter(userData?.merchant?.name)}
                </p>
              </div>
            ) : file !== "undefined" ? (
              <img src={file} alt="profile" className="w-full h-full" />
            ) : (
              <img
                src={userData?.merchant?.logo}
                alt="profile"
                className="w-full h-full"
              />
            )}
          </div>
          {/* <div className="">
            <input
              type="file"
              id="logo"
              className="hidden"
              onChange={(e) => handleFileUpload(e)}
            />
            <label
              htmlFor="logo"
              className="p-[7px] text-[#093990] cursor-pointer inline rounded-[3px] font-thin border border-[#2054D2]"
            >
              Upload Logo
            </label>

            <div className="flex flex-col gap-[5px] mt-[10px]">
              <p className="font-thin">Upload a custom logo of your business</p>
              <p className="text-[#7B7B7B] font-thin">
                We accept JPEGs, PNGs and SVGs. 3mb maximum size
              </p>
            </div>
          </div> */}
        </div>

        <div className="mt-[3rem] flex flex-col gap-[16px]">
          <CustomInputField
            type="text"
            readOnly
            label="Business Name"
            defaultValue={userData?.merchant?.name}
          />

          <CustomInputField
            type="email"
            readOnly
            label="Business Email"
            defaultValue={userData?.merchant?.email}
          />

          <CustomTextArea
            {...register("desc")}
            label="Business Description"
            defaultValue={
              userData?.merchant?.desc ? userData?.merchant?.desc : ""
            }
          />
          {/* 
            <CustomInputField
              type="text"
              readOnly
              label="BVN"
              defaultValue={userData?.bvn ? userData?.bvn : "_"}
            /> */}

          <CustomInputField
            type="text"
            readOnly
            label="Business Type"
            placeholder=""
            defaultValue={userData?.merchant?.business_type}
          />

          {/* <p className="font-thin">Business Attachments</p> */}

          {/* <div className="flex gap-[25px] lg:flex-row xxs:flex-col">
            {userDocument?.documents?.map((doc, index) => (
              <a href={doc} key={index} className="flex gap-[11px]">
                <FileIcon />
                <p className="text-[#2054D2] text-[14px] font-thin">
                  Business Attachment {index + 1}
                </p>
              </a>
            ))}
          </div> */}
        </div>

        <div className="mt-[32px]">
          <button className="py-[14px] flex gap-[10px] px-[60px] text-white bg-[#2054D2] rounded-[4px]">
            Update Profile
            {loading && <Loader />}
          </button>
        </div>
      </form>

      {/* <form onSubmit={socialSubmit(submitSocialLinkForm)}>
        <div className="mt-[50px]">
          <p className="text-[20px] text-[#0B1E4B]">Social media Links</p>
          <p className="text-[14px] font-thin text-[#0B1E4B]">
            Add your social media links that will be visible to your users
          </p>

          <div className="lg:flex lg:flex-wrap justify-between mt-[25px] xss:block ">
            <div className="lg:w-[32%] xss:w-[100%] xxs:mt-[10px]">
              <SocialLinkInput
                {...newRegister("instagram")}
                label="Instagram"
                name="instagram"
                defaultValue={userData?.links?.Instagram}
                icon={<Instagram />}
              />
              <p className="text-[12px] font-thin text-red-600">
                {newError.instagram?.message}
              </p>
            </div>
            <div className="lg:w-[32%] xss:w-[100%] xxs:mt-[10px]">
              <SocialLinkInput
                {...newRegister("twitter")}
                label="Twitter"
                name="twitter"
                defaultValue={userData?.links?.Twitter}
                icon={<Twitter />}
              />
              <p className="text-[12px] text-red-600 font-thin">
                {newError.twitter?.message}
              </p>
            </div>
            <div className="lg:w-[32%] xss:w-[100%] xxs:mt-[10px]">
              <SocialLinkInput
                {...newRegister("facebook")}
                label="Facebook"
                name="facebook"
                defaultValue={userData?.links?.Facebook}
                icon={<Facebook />}
              />
              <p className="text-[12px] text-red-600 font-thin">
                {newError.facebook?.message}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-[32px]">
          <button className="py-[14px] flex gap-[10px] px-[60px] text-white bg-[#2054D2] rounded-[4px]">
            Update Links
             {loading && <Loader />} 
          </button>
        </div>
      </form> */}
    </div>
  );
};

export default BusinessForm;
