import fileIcon from "../../../assets/images/svg/file-blue-icon.svg";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import CustomTextArea from "../../../component/CustomHTMLElements/CustomTextArea";
import attachmentIcon from "../../../assets/images/svg/attachment-blue-icon.svg";
import deleteIcon from "../../../assets/images/svg/delete-icon.svg";
import { DisputeConvoType } from "./DisputeInterface";
import moment from "moment";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { errorHandler } from "../../../helpers/errorHandler";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { Loader, SpinLoader } from "../../../component/Loader/Loader.component";
import { useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { convertToBase64 } from "../../../helpers/convertToBase64";
import { nanoid } from "nanoid";
import { truncateString } from "../../../helpers/formatter";

const schema = yup.object().shape({
  subject: yup.string().trim().required("Please enter the subject"),
  message: yup.string().trim().required("Please enter the message"),
});

export default function DisputeConversation({
  posDisputeConvoLoading,
  posDisputeConvo,
  data,
}: {
  posDisputeConvoLoading: boolean;
  posDisputeConvo: any;
  data: any;
}) {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState<any>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ message: string; subject: string }>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (posDisputeConvo?.length > 0) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [posDisputeConvo]);

  const submit = async ({
    message,
    subject,
  }: {
    message: string;
    subject: string;
  }) => {
    setLoading(true);
    const attachments = filesToBeUploaded.map((file: any) => file.photo);

    const req = {
      name: data?.data?.data?.name,
      subject: subject,
      body: message,
      // user_type: "MERCHANT",
      attachments,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.POS_ADD_CONVERSATION}/conversation/add/${data?.data?.data?.id}`,
        req
      );
      queryClient.invalidateQueries("getPosDisputeConvo");
      queryClient.invalidateQueries("getPosDisputeActivity");
      setFilesToBeUploaded([]);
      reset();
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  const onFileChange = async ({ e, id }: { e: any; id: string }) => {
    const fileName = e.target.files[0].name;
    const fileAcceptType = e.target.files[0].type;
    const isValidFile =
      fileAcceptType.startsWith("image/png") ||
      fileAcceptType.startsWith("image/jpg") ||
      fileAcceptType.startsWith("image/jpeg");
    if (!isValidFile) {
      toast.error(
        "Invalid file extension. Kindly upload a png, jpeg or jpg file"
      );
      return (e.target.value = null);
    }

    const fileBase64 = await convertToBase64(e.target.files[0]);
    const extension = fileName.split(".").pop();

    let newFile = {} as {
      fileName: string;
      extension: string;
      photo: string;
      id: string;
    };
    newFile = {
      fileName,
      extension,
      photo: fileBase64,
      id,
    };

    let newFiles = [...filesToBeUploaded, newFile];
    setFilesToBeUploaded(newFiles);
  };

  const deleteFile = (id: string) => {
    const remainingRequests = filesToBeUploaded.filter(
      (file: any) => file.id !== id
    );
    setFilesToBeUploaded([...remainingRequests]);
  };

  return (
    <>
      <div>
        <div className="py-6 px-2 lg:px-6  w-full">
          <div className="bg-[#F6F5F6] rounded-lg w-full h-[50vh] lg:h-[70vh] overflow-y-auto relative">
            {posDisputeConvoLoading ? (
              <div className="translate-y-20 flex justify-center">
                <SpinLoader />
              </div>
            ) : posDisputeConvo?.data?.data?.length > 0 ? (
              posDisputeConvo?.data?.data?.map((dispute: DisputeConvoType) => (
                <>
                  {dispute?.user_type === "MERCHANT" ? (
                    <div
                      className="w-full flex justify-start items-start my-[22px]"
                      key={dispute?.id}
                    >
                      <div className="bg-white w-6 h-6 left-triangle transform translate-x-3" />
                      <div className="w-full lg:max-w-[65%] rounded-r rounded-bl bg-white py-3 px-[19.8px]">
                        <div className="flex items-center gap-x-2">
                          <p className="text-[14px] leading-[14px] font-[400] capitalize">
                            {dispute?.name}
                          </p>
                          <p className="rounded-full w-1 h-1 bg-black "></p>
                          <p className="text-[12px] leading-[14px] font-[200] text-[#828282] capitalize">
                            {moment(dispute?.created_at).format(
                              "DD MMM YYYY, LT"
                            )}
                          </p>
                        </div>
                        <h1 className="text-[14px] leading-[16px] font-[400] pt-2.5">
                          {dispute?.subject}
                        </h1>
                        <p className="mt-2 text-[12px] leading-[16px] font-[200] text-aellaGray">
                          {dispute?.body}
                        </p>
                        <div className="mt-3 w-full flex items-center gap-x-4 flex-wrap">
                          {dispute?.attachments?.map(
                            (each: any, index: number) => (
                              <a
                                href={each.location}
                                target="_blank"
                                rel="noreferrer"
                                className="flex items-center gap-x-1 cursor-pointer"
                                key={index}
                              >
                                <img
                                  src={fileIcon}
                                  alt="File Icon"
                                  width={15}
                                  height={17}
                                />
                                <p className="text-aellaBlue text-[14px] leading-[16px] font-[200]">
                                  Attachment{" "}
                                  {dispute?.attachments?.length > 1
                                    ? index + 1
                                    : " "}
                                </p>
                              </a>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-full flex justify-end items-start my-[22px]"
                      key={data?.data?.data?.id}
                    >
                      <div className="w-full lg:max-w-[65%] rounded-l rounded-br  bg-gray-200 py-3 px-[19.8px]">
                        <div className="flex items-center">
                          <p className="text-[14px] leading-[14px] font-[400] capitalize mr-2">
                            {dispute?.name}
                          </p>
                          <p className="rounded-full w-1 h-1 bg-black "></p>
                          <p className="text-[12px] leading-[14px] font-[200] text-[#828282] capitalize ml-2">
                            {moment(dispute?.created_at).format(
                              "DD MMM YYYY, LT"
                            )}
                          </p>
                        </div>
                        <h1 className="text-[14px] leading-[16px] font-[400] pt-2.5">
                          {dispute?.subject}
                        </h1>
                        <p className="mt-2 text-[12px] leading-[16px] font-[200] text-aellaGray">
                          {dispute?.body}
                        </p>

                        <div className="mt-3 w-full flex items-center gap-x-4 flex-wrap">
                          {dispute?.attachments?.map(
                            (each: any, index: number) => (
                              <a
                                href={each.location}
                                target="_blank"
                                rel="noreferrer"
                                className="flex items-center gap-x-1 cursor-pointer"
                                key={index}
                              >
                                <img
                                  src={fileIcon}
                                  alt="File Icon"
                                  width={15}
                                  height={17}
                                />
                                <p className="text-aellaBlue text-[14px] leading-[16px] font-[200]">
                                  Attachment{" "}
                                  {dispute?.attachments?.length > 1
                                    ? index + 1
                                    : " "}
                                </p>
                              </a>
                            )
                          )}
                        </div>
                      </div>
                      <div className="bg-gray-200 w-6 h-6 left-triangle -rotate-90 transform -translate-x-3 -translate-y-[0.5px]" />
                    </div>
                  )}
                </>
              ))
            ) : (
              <div className="h-full flex items-center justify-center text-center">
                No Conversation Available
              </div>
            )}
            <div ref={bottomRef} />
          </div>
        </div>
        {data?.data?.data?.is_conversation_opened === true && (
          <div className="px-3">
            <form className="mt-6 w-full" onSubmit={handleSubmit(submit)}>
              <div className="w-full">
                <CustomInputField
                  type="text"
                  maxLength={128}
                  placeholder="Subject of message stays here..."
                  errors={errors.subject}
                  {...register("subject")}
                />
              </div>
              <div className="w-full">
                <CustomTextArea
                  maxLength={128}
                  placeholder="Write message here..."
                  errors={errors.message}
                  {...register("message")}
                />
              </div>
              <div className="flex flex-row items-start justify-between">
                <label
                  htmlFor="attachment"
                  className="h-12 inline-flex items-center gap-4 rounded-[3px] border-primary-blue border-dashed border-[1px]  px-3 py-1 cursor-pointer"
                >
                  <img
                    src={attachmentIcon}
                    alt="Attachment Icon"
                    width={20}
                    height={20}
                  />
                  <p className="text-[14px] leading-[17px] font-[200] text-aellaBlue">
                    Upload Image
                  </p>

                  <input
                    type="file"
                    name="attachment"
                    id="attachment"
                    accept="image/*"
                    className="hidden"
                    onChange={(e) =>
                      onFileChange({
                        e,
                        id: nanoid(),
                      })
                    }
                  />
                </label>
                <div className="flex justify-end">
                  <button
                    disabled={loading}
                    type="submit"
                    className={`bg-aellaBlue rounded text-white py-3 px-[11px] items-center flex justify-center cursor-pointer ${
                      loading ? "opacity-30" : ""
                    } `}
                  >
                    Send Message
                    {loading && <Loader />}
                  </button>
                </div>
              </div>
              {filesToBeUploaded?.length > 0 && (
                <div className="w-full mt-6">
                  <p className="text-[14px] leading-[16px] font-[200]">
                    Attachments
                  </p>
                  <div className="w-full mt-2 flex flex-wrap  gap-x-4 items-center">
                    {filesToBeUploaded?.map((file: any) => (
                      <div
                        className="flex items-center gap-x-1 border p-4 rounded border-aellaBlue mt-2"
                        key={file.id}
                      >
                        <img
                          src={fileIcon}
                          alt="File Icon"
                          width={15}
                          height={18}
                        />
                        <p className="text-aellaBlue text-[14px] leading-[16px] font-[200] pr-1">
                          {truncateString(file.fileName, 10)}
                          {file.extension}
                        </p>
                        <img
                          src={deleteIcon}
                          alt=""
                          className=" cursor-pointer"
                          width={15}
                          height={18}
                          onClick={() => deleteFile(file.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </>
  );
}
