import { Dispatch, FC, Fragment, SetStateAction } from "react";
import { NavLink } from "react-router-dom";

interface ITab {
  text: string;
  id?: string;
  activeTab?: string;
  setActiveTab?: Dispatch<SetStateAction<string>>;
  path?: any;
}

const Tab: FC<ITab> = ({ text, path }) => {
  return (
    <Fragment>
      <NavLink
        to={path}
        className={({ isActive }: { isActive: any }) =>
          isActive
            ? `border-b-2 text-[#111111] border-aellaBlue font-thin text-[16px] leading-[20px] px-4 py-[19px] cursor-pointer`
            : `font-thin text-[16px] leading-[20px] px-4 py-[19px] text-[#7B7B7B] cursor-pointer`
        }
      >
        {text}
      </NavLink>
    </Fragment>
  );
};

export default Tab;

interface IInnerTab {
  text: string;
  id: string;
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

export const InnerTab: FC<IInnerTab> = ({
  text,
  id,
  activeTab,
  setActiveTab,
}) => {
  return (
    <Fragment>
      <div
        className={`font-thin text-[16px] leading-[20px] px-4 py-[19px] text-[#7B7B7B] cursor-pointer ${
          activeTab === id &&
          "border-b-2 text-[#111111] border-aellaBlue font-thin text-[16px] leading-[20px] px-4 py-[19px]"
        }`}
        onClick={() => setActiveTab(id)}
      >
        {text}
      </div>
    </Fragment>
  );
};
