import Drawer from "../../../component/Drawer/Drawer";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/close-icon.svg";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Loader } from "../../../component/Loader/Loader.component";
import { useState } from "react";
import InviteFailedModal from "../Modal/InviteFailedModal";
import InviteFeedBackModal from "../Modal/InviteFeedBackModal";
import { errorHandler } from "../../../helpers/errorHandler";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";

interface ICreateMember {
  email: string;
  role: string;
  first_name: string;
  last_name: string;
  gender: string;
}

const InviteUserDrawer = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (newState: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [memberError, setMemberError] = useState("");

  const schema = yup.object().shape({
    email: yup.string().required("Email Address is Required").email(),
    role: yup.string().required("Select Role"),
    first_name: yup.string().required("First Name is Required"),
    last_name: yup.string().required("Last Name is Required"),
    gender: yup.string().required("Gender is Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ICreateMember>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: ICreateMember) => {
    setLoading(true);
    setEmail(data?.email);

    const reqBody = {
      email: data?.email,
      role: data?.role,
      first_name: data?.first_name,
      last_name: data?.last_name,
      gender: data?.gender,
    };

    try {
      const res = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.ADD_NEW_TEAM_MEMBER}`,
        reqBody
      );
      setLoading(false);
      toast.success(res.message);
      setOpenModal(true);
      reset();
    } catch (error) {
      toast.error(errorHandler(error));
      reset();
      setMemberError(errorHandler(error));
      setLoading(false);
      setIsOpen(false);
      setErrorModal(true);
      reset();
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <>
          <div className="flex flex-col">
            <div className="flex border-b pb-[12px] justify-between ">
              <p className="text-[24px] text-[#0B1E4B]">Add Team Member</p>
              <div
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <CloseIcon className="w-[32px] h-[32px] cursor-pointer" />
              </div>
            </div>
            <div className="py-[32px] flex-1 overflow-y-auto">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-[10px] flex flex-col gap-[30px]"
              >
                <div className="border rounded-[10px] p-[16px]">
                  <div className="flex justify-between">
                    <div className="w-[48%]">
                      <CustomInputField
                        {...register("first_name")}
                        type="text"
                        label="First Name"
                        name="first_name"
                        errors={errors.first_name}
                        errorMessage={errors?.first_name?.message}
                      />
                    </div>

                    <div className="w-[48%]">
                      <CustomInputField
                        type="text"
                        label="Last Name"
                        {...register("last_name")}
                        name="last_name"
                        errors={errors.last_name}
                        errorMessage={errors?.last_name?.message}
                      />
                    </div>
                  </div>

                  <CustomInputField
                    type="email"
                    label="Email Address"
                    {...register("email")}
                    name="email"
                    errorMessage={errors?.email?.message}
                    errors={errors.email}
                  />

                  <CustomSelectDropdown
                    errorMessage={errors?.gender?.message}
                    errors={errors.gender}
                    name="gender"
                    label="Gender"
                    {...register("gender")}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </CustomSelectDropdown>

                  <div>
                    <p className="text-[18px] text-[#0B1E4B]">Select Role</p>

                    <div className="mt-[34px] flex flex-col gap-[30px]">
                      <div className="flex gap-[11px] items-start">
                        <input
                          type="radio"
                          {...register("role")}
                          name="role"
                          id="admin"
                          value="Admin"
                          className="mt-[.5rem]"
                          checked
                        />
                        <div>
                          <p className="text-[#0B1E4B] mb-[10px]">Admin</p>
                          <p className="text-[#7B7B7B] font-thin">
                            Admin doesn’t have full control over the activities
                            on this platform. His permissions including editing,
                            but cannot invite other admin into the platform
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-[11px] items-start">
                        <input
                          type="radio"
                          {...register("role")}
                          name="role"
                          id="SuperAdmin"
                          value="SuperAdmin"
                          className="mt-[.5rem]"
                        />
                        <div>
                          <p className="text-[#0B1E4B] mb-[10px]">
                            Super Admin
                          </p>
                          <p className="text-[#7B7B7B] font-thin">
                            A super admin has control over all activities on
                            this plaform. His persmission includes mananging
                            billing settings, inviting other admins, viewing,
                            editing and deleting
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="text-[#DD4F05] font-light text-[0.8rem]">
                      {errors?.role?.message}
                    </p>
                  </div>
                </div>

                <div className="mt-[30px] flex justify-end gap-[8px]">
                  <div
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    className="w-[156px] rounded-[4px] text-center bg-[#ECEDF0] p-[14px] text-[#232323]"
                  >
                    Cancel
                  </div>

                  <button className="w-[156px] rounded-[4px] flex items-center justify-center gap-[5px] bg-[#2054D2] p-[14px] text-white">
                    <span>Send Invite</span>{" "}
                    <span>{loading && <Loader />}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      </Drawer>

      {errorModal && (
        <InviteFailedModal
          showModal={errorModal}
          message={memberError}
          setCloseModal={setErrorModal}
        />
      )}

      {openModal && (
        <InviteFeedBackModal
          showModal={openModal}
          setCloseModal={setOpenModal}
          mail={email}
        />
      )}
    </>
  );
};

export default InviteUserDrawer;
