import moment from "moment";
import { useEffect, useState } from "react";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import Pagination from "../../../component/Pagination/Pagination.component";
import {
  attachClassNameToStatus,
  getStatusNameByNumber,
} from "../../../helpers/attachClassNameToTag";
import { currencyFormat } from "../../../helpers/formatter";
import { useAllSubAccountTransactions } from "../../../hooks/useAllSubAccountTransactions";
import InflowTransactionDrawer from "../../InflowTransactions/Drawer/InflowTransactionDrawer";

export default function SubaccountTransactions({
  id,
  setFilter,
}: {
  id: string;
  setFilter: any;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(20);
  const [isOpen, setIsOpen] = useState(false);
  const [transaction, setTransaction] = useState<any>(null);

  const {
    data: subAccountTransactions,
    isLoading: subAccountTransactionsLoading,
  } = useAllSubAccountTransactions({
    page: currentPage,
    size: postsPerPage,
    id,
  });
  useEffect(() => {
    setFilter({
      startDate: "",
      endDate: "",
    });
  }, []);

  return (
    <>
      <div className="bg-white md:w-full overflow-y-auto overflow-x-auto md:overflow-x-hidden hide_scrollbar">
        <div className="mt-4 mb-14">
          <div className="flex items-center bg-[#F7F9FC] w-[400%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 py-2 text-aellaBlack text-sm border-b">
            <p className="w-[25%] capitalize">Amount </p>
            <p className="w-[18%] capitalize">Transaction type</p>
            <p className="w-[20%] text-center capitalize">Status</p>
            <p className="flex-1 text-right capitalize ">Date Created</p>
          </div>
          {subAccountTransactionsLoading ? (
            <SecondaryLoader count={10} />
          ) : subAccountTransactions?.data?.length > 0 ? (
            subAccountTransactions?.data?.map((details: any) => {
              return (
                <div
                  key={details?.id}
                  onClick={() => {
                    setIsOpen(true);
                    setTransaction(details);
                  }}
                  className="flex items-center bg-white w-[400%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b last:border-none cursor-pointer"
                >
                  <p className="w-[25%]">{currencyFormat(details?.amount)}</p>
                  <p
                    className={`w-[18%] capitalize  text-[13px] lg:text-[16px] lg:leading-[19px] tracking-[0.2px] font-[300] py-[15px] ${
                      details?.type === "cr"
                        ? "text-[#219653]"
                        : details?.type === "dr"
                        ? "text-[#EB5757]"
                        : "text-aellaGray"
                    }`}
                  >
                    {details?.type === "dr"
                      ? "Debit"
                      : details?.type === "cr"
                      ? "Credit"
                      : ""}
                  </p>

                  <p className={`w-[20%] text-center`}>
                    <span
                      className={`w-full text-center ${attachClassNameToStatus(
                        details?.status
                      )} `}
                    >
                      {getStatusNameByNumber(details?.status)}
                    </span>
                  </p>

                  <p className="flex-1 text-right">
                    {details?.date
                      ? moment(details?.date).format("DD MMM YYYY, LT")
                      : "-"}
                  </p>
                </div>
              );
            })
          ) : (
            <div className="h-full flex items-center justify-center text-center">
              No Transaction Found
            </div>
          )}
        </div>
        {subAccountTransactions?.data?.length > 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={subAccountTransactions?.meta?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={subAccountTransactions?.meta?.total}
            params={false}
          />
        )}
      </div>
      <InflowTransactionDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        transaction={transaction}
      />
    </>
  );
}
