export enum apiEndpoints {
  LOGIN = "/user/login",
  CREATE_MERCHANT = "/auth/onboard/initiate",
  RESEND_OTP = "/auth/onboard/resend-otp",
  CHECK_EMAIL_EXIT = "/auth/onboard/exists",
  VERIFY_MERCHANT_PHONE_NUMBER = "/merchant/verifyphone",
  VERIFY_USER_PHONE_NUMBER = "/user/verifyphone",
  GET_ALL_SERVICES = "/product/product-types",
  VALIDATE_OTP = "/auth/onboard/verify",
  VALIDATE_UPDATED_NUMBER_OTP = "/auth/onboard/verify-phone-number-update",
  ADD_NEW_TEAM_MEMBER = "/member",
  DELETE_TEAM_MEMBER = "/member",
  CHANGE_TEAM_ROLE = "/member/change-role",
  REVOKE_TEAM_MEMBER = "/member/revoke",
  UPDATE_USER_PHONE_NUMBER = "/auth/onboard/update-phone-number",
  CREATE_MERCHANT_SERVICE = "/auth/onboard/add-products",
  UPDATE_MERCHANT_SERVICES = "/product",
  GET_PROFILE_DOCUMENTS = "/auth/documents",
  TERMS = "/auth/terms-and-conditions",
  GET_ALL_TOKENS = "/token",
  GET_MERCHANT_DETAILS = "/auth/profile",
  ADD_BULK_SOCIAL_MEDIA_LINKS = "/link/bulk",
  GET_ADMINS_LIST = "/member/filter",
  SOCIAL_LINKS = "/link",
  GET_MERCHANT_WALLET = "/wallet/merchant/balance/all",
  GET_MERCHANT_SERVICES = "/product",
  GET_ALL_COUNTRIES = "/list/countries",
  GET_ALL_STATES = "/list/states",
  GET_MERCHANT_PROFILE = "/merchant/profile/",
  INITIATE_RESET_PASSWORD = "/user/reset-password/initiate",
  RESET_PASSWORD = "/user/reset-password/update",
  CREATE_UNREG_PROFILE = "/auth/upgrade/unregistered/profile",
  CREATE_UNREG_DETAILS = "/auth/upgrade/unregistered/detail",
  CREATE_UNREG_ADDRESS = "/auth/upgrade/unregistered/address",
  CREATE_REG_BUSINESS_TYPE = "/auth/upgrade/registered/type",
  CREATE_REG_OWNER = "/auth/upgrade/registered/owner",
  CREATE_REG_BULK_OWNER = "/auth/upgrade/registered/owner/bulk",
  CREATE_REG_DETAILS = "/auth/upgrade/registered/detail",
  CREATE_REG_ADDRESS = "/auth/upgrade/registered/address",
  GET_WITHDRAWAL_BANKS = "/transfer/merchant/banks",
  VALIDATE_BANKS = "/transfer/merchant/resolve-account",
  CREATE_TRANSACTION_PIN = "/user/pin/create",
  CREATE_MERCHANT_TRANSACTION_PIN = "/merchant/transactionpin",
  GET_WALLET_BALANCE = "/wallet/merchant/balance",
  GET_WALLET_TRANSACTION_SUMARRY = "/wallet/merchant/transaction/summary",
  GET_POS_WALLET_TRANSACTIONS = "/pos/merchant/wallet",
  GET_WALLET_TRANSACTIONS = "/wallet/merchant/transaction",
  ENVIRONMENT = "/environment",
  SET_ENVIRONMENT = "/environment/toggle",
  CHECK_APPROVAL_LOG = "/auth/onboard/approval-log",
  GET_MERCHANT_WITHDRAWAL_ACCOUNTS = "/transfer/merchant/beneficiary",
  WITHDRAW_FROM_WALLET = "/transfer/merchant/disburse_merch",
  MERCHANT_ACCOUNT = "/account/merchant",
  ADD_WITHDRAWAL_ACCOUNT = "/transfer/merchant/beneficiary",
  WALLET_TO_WALLET_TRANSFER = "/transfer/merchant/p2p/intra",
  DEACTIVATE_WALLET_ACCOUNT = "/transfer/merchant/beneficiary/deactivate",
  API_SECRET_KEY = "/merchant/merchantkey",
  POS_ANALYTICS = "/pos/requestanalytics",
  POS_REQUESTS = "/pos/merchant/request",
  POS_TERMINALS = "/pos/merchant/requests",
  POS_TRANSACTIONS = "/pos/merchant/transactions",
  GET_INFLOW_TRANSACTIONS = "/inflow/internal/search",
  GET_INFLOW_TRANSACTIONS_SUM = "/inflow/internal/value",
  GET_ALL_MERCHANT_SERVICES = "/service/allmerchantwallets",
  GET_MERCHANT_BANK_ACCOUNT = "/account/merchant/",
  GENERATE_ACCOUNT = "/bank-account/generate",
  TRANSFER_TO_BANK = "/transfer/banks",
  TRANSFER_TO_WALLET = "/transfer/merchant/p2p/merch",
  GET_WALLET_DETAILS = "/user",
  GET_TRANSFER_LIST = "/transfer/merchant/list",
  GET_TRANSFER_FEE = "/transfer/merchant/fee",
  POS_DISPUTES = "/pos/merchant/disputes",
  TEAM_MEMBER = "/member",
  POS_ADD_CONVERSATION = "/pos/merchant/dispute",
  UPLOAD_MERCHANT_PROFILE = "/merchant/socialslogo",
  CHANGE_PASSWORD = "/user/reset-password/change",
  UPDATE_NOTIFICATION = "/auth/notification-preferences",
  GET_NOTIFICATION = "/notification/preference/get",
  GET_BULK_TRANSFER_LIST = "/transfer/merchant/list/bulk",
  RESOLVE_BULK_ACCOUNTS = "/transfer/merchant/multi-resolve",
  GET_BANK_SORT_CODES = "/transfer/merchant/sort_code",
  INITIATE_BULK_TRANSFER = "/transfer/merchant/multi-dispatch",
  GET_AGENTS_LIST = "/agent",
  SUBACCOUNTS = "/subaccount",
  UPDATE_PASSWORD = "/user/reset-password/update",
  EXPORT_LIST = "/account-statement/statements",
  UPLOAD_IMAGE = "/image-recognition/upload ",
}
