import { getFirstLetter } from "../../../helpers/capitalizeFirstLetter";

import { ReactComponent as OffDoubleCheckIcon } from "../../../assets/images/svg/gray-double-check-icon.svg";
import { ReactComponent as OnDoubleCheckIcon } from "../../../assets/images/svg/double-check-icon.svg";
import ProfileStepOne from "./UnregisteredSteps/ProfileStepOne";
import { useEffect, useState } from "react";
import { useMerchantDetails } from "../../../hooks/useMerchantDetails";
import { SpinLoader } from "../../../component/Loader/Loader.component";
import ProfileStepTwo from "./UnregisteredSteps/ProfileStepTwo";
import ProfileStepThree from "./UnregisteredSteps/ProfileStepThree";
import ProfileStepFour from "./UnregisteredSteps/ProfileStepFour";
import { useApprovalLog } from "../../../hooks/useApprovalLog";
import { useAuth } from "../../../context/auth";
import { ROUTES } from "../../../helpers/routes";
import { useNavigate } from "react-router-dom";

export default function Unregistered() {
  const { setCredentials } = useAuth();
  const [step, setStep] = useState(1);
  const [reasons, setReasons] = useState([]);
  const { data: merchantDetails, isLoading } = useMerchantDetails({});
  const { data: log } = useApprovalLog({
    merchantId:
      merchantDetails?.attributes?.profile?.upgrade_status === "Disapproved"
        ? merchantDetails?.merchant_id
        : "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (merchantDetails) {
      setCredentials({ isAuth: true, merchantDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  useEffect(() => {
    if (log) {
      const lastDeclined = log?.find((data: any) => {
        return data?.status === "Declined";
      });
      setReasons(lastDeclined?.comment.split(","));
    }
  }, [log]);

  useEffect(() => {
    if (merchantDetails) {
      switch (true) {
        case !merchantDetails?.attributes?.profile?.hasOwnProperty(
          "upgrade_stage"
        ):
          return setStep(1);
        case merchantDetails?.attributes?.profile?.upgrade_stage === "Profile":
          return setStep(2);
        case merchantDetails?.attributes?.profile?.upgrade_stage === "Detail":
          return setStep(3);
        case merchantDetails?.attributes?.profile?.upgrade_stage ===
          "Address" && merchantDetails?.isTransactionPinSet === false:
          return setStep(4);
        case merchantDetails?.attributes?.profile?.upgrade_stage ===
          "Address" && merchantDetails?.isTransactionPinSet === true:
          return navigate(ROUTES.HOME, {
            state: { profile: true, profileSuccess: true },
          });

        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  return (
    <>
      <div className="block lg:flex flex-row  justify-between">
        <div className="hidden lg:flex flex-col lg:w-[25vw] ">
          <div className="flex flex-col border border-[#EDF2FF] bg-white p-6 rounded-2xl items-center">
            <div className="inline-flex border rounded-full bg-[#967164] w-[80px] h-[80px] items-center justify-center cursor-pointer relative">
              {merchantDetails?.merchant?.trading_name && (
                <div className="text-aellaBlue absolute text-4xl">
                  {getFirstLetter(merchantDetails?.merchant?.trading_name)}
                </div>
              )}
            </div>
            {/* <div className="mt-[23px]">
              <input
                type="file"
                id="logo"
                className="hidden"
                onChange={(e) => handleFileUpload(e)}
              />
              <label
                htmlFor="logo"
                className="p-[7px] text-[#093990] cursor-pointer inline rounded-[3px] font-thin border border-[#2054D2] items-center"
              >
                Upload Logo
              </label>
            </div> */}
            <h1 className="pt-[23px] text-[24px] lg:leading-[29px] font-[300] capitalize">
              {merchantDetails?.merchant?.trading_name}
            </h1>
            <div
              className={`py-[13px] px-2.5 flex rounded border ${
                step === 1 ? "border-aellaBlue" : "border-[#E0E0E0]"
              } items-center justify-between w-full cursor-pointer mt-[23px]`}
              onClick={() => setStep(1)}
            >
              <div
                className={`flex items-center text-[16px] lg:leading-[19px] ${
                  step === 1
                    ? "text-aellaGray font-[100]"
                    : "text-[#232323] font-[200]"
                }`}
              >
                <span className="pr-1.5">1.</span>
                <span className="">Personal Details</span>
              </div>

              {!merchantDetails?.attributes?.profile?.hasOwnProperty(
                "upgrade_stage"
              ) ? (
                <OffDoubleCheckIcon />
              ) : (
                <OnDoubleCheckIcon />
              )}
            </div>
            <div
              className={`py-[13px] px-2.5 flex rounded border ${
                step === 2 ? "border-aellaBlue" : "border-[#E0E0E0]"
              } items-center justify-between w-full cursor-pointer mt-2`}
              onClick={() => {
                if (
                  merchantDetails?.attributes?.profile?.hasOwnProperty(
                    "upgrade_stage"
                  )
                ) {
                  setStep(2);
                }
              }}
            >
              <div
                className={`flex items-center text-[16px] lg:leading-[19px] ${
                  step === 1
                    ? "text-aellaLightGray font-[100]"
                    : step === 2
                    ? "text-aellaGray font-[50]"
                    : "text-[#232323] font-[200]"
                }`}
              >
                <span className="pr-1.5">2.</span>
                <span className="">Business Details</span>
              </div>

              {!merchantDetails?.attributes?.profile?.hasOwnProperty(
                "upgrade_stage"
              ) ||
              merchantDetails?.attributes?.profile?.upgrade_stage ===
                "Profile" ? (
                <OffDoubleCheckIcon />
              ) : (
                <OnDoubleCheckIcon />
              )}
            </div>
            <div
              className={`py-[13px] px-2.5 flex rounded border ${
                step === 3 ? "border-aellaBlue" : "border-[#E0E0E0]"
              } items-center justify-between w-full cursor-pointer mt-2`}
              onClick={() => {
                if (
                  merchantDetails?.attributes?.profile?.upgrade_stage ===
                    "Detail" ||
                  merchantDetails?.attributes?.profile?.upgrade_stage ===
                    "Address"
                ) {
                  setStep(3);
                }
              }}
            >
              <div
                className={`flex items-center text-[16px] lg:leading-[19px] ${
                  step < 3
                    ? "text-aellaLightGray  font-[100]"
                    : step === 3
                    ? "text-aellaGray font-[50]"
                    : "text-[#232323] font-[200]"
                }`}
              >
                <span className="pr-1.5">3.</span>
                <span className="">Business Address</span>
              </div>

              {!merchantDetails?.attributes?.profile?.hasOwnProperty(
                "upgrade_stage"
              ) ||
              merchantDetails?.attributes?.profile?.upgrade_stage ===
                "Profile" ||
              merchantDetails?.attributes?.profile?.upgrade_stage ===
                "Detail" ? (
                <OffDoubleCheckIcon />
              ) : (
                <OnDoubleCheckIcon />
              )}
            </div>

            <div
              className={`py-[13px] px-2.5 flex rounded border ${
                step === 4 ? "border-aellaBlue" : "border-[#E0E0E0]"
              } items-center justify-between w-full cursor-pointer mt-2`}
              onClick={() => {
                if (
                  merchantDetails?.attributes?.profile?.upgrade_stage ===
                  "Address"
                ) {
                  setStep(4);
                }
              }}
            >
              <div
                className={`flex items-center text-[16px] lg:leading-[19px] ${
                  step < 4
                    ? "text-aellaLightGray font-[100]"
                    : step === 4
                    ? "text-aellaGray  font-[50]"
                    : "text-[#232323]  font-[200]"
                }`}
              >
                <span className="pr-1.5">4.</span>
                <span className="">Transaction Pin</span>
              </div>

              {merchantDetails?.attributes?.profile?.upgrade_stage !==
                "Address" || merchantDetails?.isTransactionPinSet === false ? (
                <OffDoubleCheckIcon />
              ) : (
                <OnDoubleCheckIcon />
              )}
            </div>
          </div>
          <h1 className="flex items-center text-[16px] text-[#232323] lg-[19px] font-[200] pt-6">
            Complete this to get your profile fully running 🎉
          </h1>
          {merchantDetails?.attributes?.profile?.upgrade_status ===
            "Disapproved" && (
            <div className="border border-[#F0D5C7] rounded-[16px] p-6 my-[28px] bg-[#FFFCF4]">
              <h1 className="flex items-center text-[18px] text-[#232323] lg-[19px] font-[200]">
                Reasons for disapproval
              </h1>
              <ul className="list-disc list-inside ml-2">
                {reasons?.map((reason) => {
                  return (
                    <li className="text-[16px] text-aellaGray lg-[24px] font-[200] tracking-[0.2px]">
                      {reason}{" "}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="lg:w-[50vw] bg-white p-6 rounded-2xl w-full mb-10">
          {(() => {
            switch (true) {
              case isLoading:
                return <SpinLoader />;
              case step === 1:
                return <ProfileStepOne merchantDetails={merchantDetails} />;

              case step === 2:
                return <ProfileStepTwo merchantDetails={merchantDetails} />;
              case step === 3:
                return <ProfileStepThree merchantDetails={merchantDetails} />;

              case step === 4:
                return <ProfileStepFour merchantDetails={merchantDetails} />;

              default:
                return;
            }
          })()}
        </div>
      </div>
    </>
  );
}
