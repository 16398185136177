import { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as FailedTransferIcon } from "../../../assets/images/svg/failed-transfer.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import BlueButton from "../../../component/Button/BlueButton";
import SuccessAnimation from "../../../assets/files/Stars.json";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";

const ExportStatusModal = ({
  showModal,
  setShowModal,
  status,
  setStatus,
}: {
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  status: string;
  setStatus: (newState: string) => void;
}) => {
  const userEmail = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
  const FailedStatus = () => {
    return (
      <>
        <div className="flex justify-between items-center">
          <div
            onClick={() => setShowModal(false)}
            className="absolute -top-8 -right-0 cursor-pointer"
          >
            <CloseIcon />
          </div>
        </div>
        <div className="flex flex-col justify-between items-center">
          <FailedTransferIcon />
          <p className="text-aellaBlack text-[24px] mt-6 text-center">
            Oops.. Something went wrong
          </p>
          <p className="text-[#5b5b5b] font-light text-center mt-3">
            Sorry, we couldn't proceed with your data export, please try again
            later.
          </p>
        </div>
      </>
    );
  };

  const SuccessStatus = () => {
    return (
      <>
        <div className="flex justify-between items-center">
          <div
            onClick={() => setShowModal(false)}
            className="absolute -top-8 -right-0 cursor-pointer"
          >
            <CloseIcon />
          </div>
        </div>
        <div className="flex flex-col justify-between items-center">
          <div className="flex items-center justify-center h-auto relative">
            <Player
              autoplay
              controls
              loop
              src={SuccessAnimation}
              style={{
                width: "200px",
                height: "120px",
              }}
            />
            <p className="absolute top-[30%] right-[25%] text-2xl text-aellaBlue">
              Successful
            </p>
          </div>
          <p className="text-aellaBlack text-[24px] mt-6">Data Exported</p>
          <p className="text-[#5b5b5b] font-light text-center mt-3">
            The data requested has been sent to{" "}
            <strong className="text-aellaBlack">{userEmail}</strong>
          </p>
          <BlueButton
            onClick={() => setShowModal(false)}
            title="Go Back"
            className="mt-8"
          />
        </div>
      </>
    );
  };

  const renderBody = () => {
    switch (status) {
      case "success":
        return <SuccessStatus />;
      case "failed":
        return <FailedStatus />;
      default:
        break;
    }
  };

  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        setShowModal(false);
      }}
    >
      <div className="xsw-full lg:w-[30vw] md:w-[60vw] relative h-auto lg:max-h-[85vh] xl:min-h-[40vh] z-50 text-aellaBlack flex flex-col items-center justify-center rounded-[8px] bg-white py-8 px-6">
        <>{renderBody()}</>
      </div>
    </ModalContainer>
  );
};

export default ExportStatusModal;
