import { useState } from "react";
import AdminList from "../Components/AdminList";
import PendingInviteList from "../Components/PendingInviteList";
import SuperAdminList from "../Components/SuperAdminList";
import InviteUserDrawer from "../Drawer/InviteUserDrawer";
import { ROUTES } from "../../../helpers/routes";
import Tab from "../../../component/Tab/Tab.component";

const ManageTeam = () => {
  const [stage, setStage] = useState("admins");
  const [isOpen, setIsOpen] = useState(false);

  const RenderContent = () => {
    switch (stage) {
      case "admins":
        return <AdminList />;
      case "super_admins":
        return <SuperAdminList />;
      case "pending_invites":
        return <PendingInviteList />;
      default:
        return;
    }
  };
  return (
    <>
      <div className="border-y border-[#e1dddd] bg-aellaDimGray overflow-x-auto h-screen">
        <div className="bg-white pt-[7px] px-[10px] lg:px-10 overflow-x-auto">
          <div className="flex justify-between bg-white items-center pt-2 pb-[1px] flex-wrap border-t border-[#F1F1F1] w-[200%] xs:w-[165%] sm:w-full">
            <div className="flex ">
              <Tab text="Profile" path={ROUTES.SETTINGS_PROFILE} />
              <Tab text="Security" path={ROUTES.SETTINGS_SECURITY} />
              <Tab text="Notifications" path={ROUTES.SETTINGS_NOTIFICATIONS} />
              {/* <Tab text="Manage Team" path={ROUTES.SETTINGS_MANAGE_TEAM} />
              <Tab
                text="Withdrawal Bank Accounts"
                path={ROUTES.SETTINGS_WITHDRAWAL}
              /> */}
              {/* <Tab text="APi Keys & Webhooks" path={ROUTES.SETTINGS_API} /> */}
            </div>
          </div>
        </div>
        <div className="py-[32px] px-10">
          <div className="bg-white border border-[#EDEFF5] p-10 pt-[30px] min-h-[70vh]">
            <div className="">
              <div className="pb-[12px] border-b border-[#EDEFF5] flex justify-between">
                <p className="text-[24px] text-[#0B1E4B]">Manage Team</p>

                <button
                  onClick={() => setIsOpen(true)}
                  className="px-[40px] py-[14px] rounded-[4px] text-[14px] text-white bg-[#2054D2]"
                >
                  Invite Team
                </button>
              </div>
            </div>

            <div className="mt-[40px]">
              <div className="mb-[17px] bg-[#F7F8F8] border border-[#EDF2FF] py-[4px] px-[8px] gap-[16px] rounded-[5rem] inline-flex">
                <div
                  onClick={() => setStage("admins")}
                  className={`text-[#232323] font-thin cursor-pointer  ${
                    stage === "admins"
                      ? "border-[#ECEDF0] border bg-white shadow-md"
                      : ""
                  }  px-[16px] py-[8px] rounded-[4rem] `}
                >
                  Admins
                </div>

                <div
                  onClick={() => setStage("super_admins")}
                  className={`text-[#232323] font-thin cursor-pointer ${
                    stage === "super_admins"
                      ? "border-[#ECEDF0] border  bg-white shadow-md"
                      : ""
                  }  px-[16px] py-[8px] rounded-[4rem] `}
                >
                  Super Admins
                </div>

                <div
                  onClick={() => setStage("pending_invites")}
                  className={`text-[#232323] font-thin cursor-pointer ${
                    stage === "pending_invites"
                      ? "border-[#ECEDF0] border  bg-white shadow-md"
                      : ""
                  }  px-[16px] py-[8px] rounded-[4rem] `}
                >
                  Pending Invites
                </div>
              </div>
            </div>

            {RenderContent()}

            <div className="mt-[70px]">
              <div className="pb-[12px] border-b border-[#EDEFF5] flex justify-between">
                <p className="text-[24px] text-[#0B1E4B]">User Access</p>
              </div>

              <p className="font-thin mt-[36px]">
                The following are are the regulations tied to users on this
                platform
              </p>

              <div className="flex gap-[8px]">
                <div className="w-[325px] mt-[18px]">
                  <p className="text-[#0B1E4B] mb-[16px]">Super Admin</p>

                  <p className="text-[#5B5B5B] font-thin">
                    A super admin has control over all activities on this
                    plaform. His persmission includes mananging billing
                    settings, inviting other admins, viewing, editing and
                    deleting
                  </p>
                </div>

                <div className="w-[325px] mt-[18px]">
                  <p className="text-[#0B1E4B] mb-[16px]">Admin</p>

                  <p className="text-[#5B5B5B] font-thin">
                    Admin doesn’t have full control over the activities on this
                    platform. His permissions including editing, but cannot
                    invite other admin into the platform
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InviteUserDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default ManageTeam;
