import { ReactComponent as Drop } from "../../../assets/images/svg/drop-icon.svg";
import { ReactComponent as Delete } from "../../../assets/images/svg/delete-icon2.svg";
import { useState } from "react";
import DeleteAdminModal from "../Modal/DeleteAdminModal";

import { useFilterTeams } from "../../../hooks/useFilterTeams";
import { postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { toast } from "react-toastify";
import { errorHandler } from "../../../helpers/errorHandler";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";

const AdminList = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [userId, setUserId] = useState("");

  const { data, isLoading, refetch } = useFilterTeams({
    type: "Admin",
    size: "15",
  });

  const changeRole = async () => {
    const reqBody = {
      user_id: userId,
      role: "SuperAdmin",
    };

    try {
      const res = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CHANGE_TEAM_ROLE}`,
        reqBody
      );
      refetch();
      toast.success(res.message);
    } catch (error) {
      toast.error(errorHandler(error));
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
        </div>
      ) : (
        <div className="w-[80%] border border-[#EDEFF5]">
          <div className="bg-[#F3F5FC] flex p-[14px]">
            <p className="w-[20%]  text-[#0B1E4B]">Full Name</p>
            <p className="w-[20%] text-[#0B1E4B] ">Email Address</p>
            <p className="w-[20%] text-[#0B1E4B] ">Gender</p>
            <p className="w-[20%] text-[#0B1E4B] ">Role</p>
            <p className="w-[20%] text-[#0B1E4B] ">Action</p>
          </div>
          {data?.length === 0 ? (
            <div className="flex flex-col gap-[32px] mt-[77px] w-[40%] mx-auto justify-center items-center">
              <div className="text-center">
                <p className="text-[#232323] mb-[8px]">No Bank Account </p>
                <p className="font-thin text-[#5B5B5B]">
                  You have not added a bank account to this platform. Click the
                  button below to add your bank account.
                </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-[16px] my-[16px]">
              {data?.data?.map((each) => (
                <div key={each?.id} className="flex p-[14px] ">
                  <div className="w-[20%] text-[#3C4249] font-thin">
                    {each?.user?.first_name} {each?.user?.last_name}
                  </div>
                  <div className="w-[20%] text-[#3C4249] font-thin">
                    <p className="w-[95%] truncate"> {each?.user?.email}</p>
                  </div>
                  <div className="w-[20%] text-[#3C4249] font-thin">
                    {each?.user?.gender ? each?.user?.gender : "_"}
                  </div>
                  <div className="w-[20%] text-[#3C4249] font-thin flex gap-[14px] items-center">
                    <p>{each?.type}</p>
                    <div className="relative myHovered">
                      <Drop />

                      <div
                        onClick={changeRole}
                        onMouseOver={() => setUserId(each?.user_id)}
                        className="myHovered2 invisible transition-all py-[10px] cursor-pointer absolute top-[105%] left-[-200%] z-20 w-[200px] text-center bg-white shadow-md rounded-[3px]"
                      >
                        Change to SuperAdmnin
                      </div>
                    </div>
                  </div>

                  <div
                    onMouseOver={() => setUserId(each?.user_id)}
                    onClick={() => setOpenDeleteModal(true)}
                    className="w-[20%] cursor-pointer text-[#3C4249] font-thin flex gap-[14px] items-center"
                  >
                    <Delete />
                    <p className="text-[#FE4149] font-thin">Delete</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {openDeleteModal && (
        <DeleteAdminModal
          refetchUsers={refetch}
          id={userId}
          showModal={openDeleteModal}
          setCloseModal={setOpenDeleteModal}
        />
      )}
    </>
  );
};

export default AdminList;
