import { ReactComponent as Delete } from "../../../assets/images/svg/delete-icon2.svg";
import { useState } from "react";
import RevokeInviteModal from "../Modal/RevokeInviteModal";
import { useAllTeams } from "../../../hooks/useAllteams";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";

export type SearchType = {
  startDate?: Date | null;
  endDate?: Date | null;
  search?: string;
  page: number;
  size: number;
};

const PendingInviteList = () => {
  const [inviteId, setInviteId] = useState("");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(40);

  const [preSearchState, setPreSearchState] = useState<SearchType>({
    page: currentPage,
    size: postsPerPage,
  });
  const { data, isLoading, refetch } = useAllTeams(preSearchState);
  // const [searchState, setSearchState] = useState<SearchType>({
  //   startDate: null,
  //   endDate: null,
  //   search: "",
  //   page: currentPage,
  //   size: postsPerPage,
  // });

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
        </div>
      ) : (
        <div className="w-[80%] border border-[#EDEFF5]">
          <div className="bg-[#F3F5FC] flex p-[14px]">
            <p className="w-[25%]  text-[#0B1E4B]">Full Name</p>
            <p className="w-[20%] text-[#0B1E4B] ">Email Address</p>
            <p className="w-[15%] text-[#0B1E4B] ">Gender</p>
            <p className="w-[20%] text-[#0B1E4B] ">Role</p>
            <p className="w-[20%] text-[#0B1E4B] ">Action</p>
          </div>

          <div className="flex flex-col gap-[16px] my-[16px]">
            {data?.data?.map((admin: any, index: any) => (
              <div key={index} className="flex p-[14px] ">
                <div className="w-[25%] text-[#3C4249] font-thin">
                  {admin?.user?.first_name} {admin?.user?.last_name}
                </div>
                <div className="w-[20%] text-[#3C4249] font-thin">
                  {admin?.user?.email}
                </div>

                <div className="w-[15%] text-[#3C4249] font-thin">
                  {admin?.user?.gender ? admin?.user?.gender : "_"}
                </div>

                <div className="w-[20%] text-[#3C4249] font-thin flex gap-[14px] items-center">
                  <p>{admin?.type}</p>
                </div>

                <div
                  onClick={() => {
                    setInviteId(admin?.user?.id);
                    setOpenDeleteModal(true);
                  }}
                  className="w-[20%] cursor-pointer text-[#3C4249] font-thin flex gap-[14px] items-center"
                >
                  <Delete />
                  <p className="text-[#FE4149] font-thin">Revoke Invite</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {openDeleteModal && (
        <RevokeInviteModal
          inviteeId={inviteId}
          showModal={openDeleteModal}
          refetchList={() => refetch()}
          setCloseModal={setOpenDeleteModal}
        />
      )}
    </>
  );
};

export default PendingInviteList;
