import { Dispatch, SetStateAction } from "react";
import { Link, NavLink } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import Sidebar from "./Sidebar.component";
import Logo from "../../assets/images/svg/aella-white-logo.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/svg/white-close-icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/svg/settings-icon.svg";
import { useAuth } from "../../context/auth";
import { useMerchantDetails } from "../../hooks/useMerchantDetails";

const SidebarNav = ({
  smallScreenSideBar,
  toggleSidebar,
}: {
  smallScreenSideBar: boolean;
  toggleSidebar: Dispatch<SetStateAction<any>>;
}) => {
  const { merchantCntxtStatus } = useAuth();
  const { data: merchantDetails } = useMerchantDetails({});

  const hasCompletedProfileCompletion =
    merchantDetails?.attributes?.hasOwnProperty("unregisteredAddress") &&
    merchantDetails?.attributes?.hasOwnProperty("unregisteredDocument") &&
    merchantDetails?.attributes?.hasOwnProperty("unregisteredOwnership") &&
    merchantDetails?.pin;

  const shouldShowBtn =
    merchantDetails?.merchant?.unregistered_status === "Approved" &&
    merchantDetails?.merchant?.business_type === "Unregistered";

  return (
    <>
      <div className="bg-aellaBlue hidden lg:flex flex-col w-[15%] py-6 h-screen text-white transition ease-in-out duration-300">
        <div className="overflow-y-auto h-screen ">
          <div className="px-5 pb-5 border-b border-white border-opacity-30">
            <Link to={ROUTES.HOME}>
              <img src={Logo} alt="Aella Logo" />
            </Link>
            <p className="text-[14px] text-[#F7F8F8] lg:leading-[17px] tracking-[2.2px] pt-[10px] font-[300]">
              MERCHANT
            </p>
          </div>
          {/* <div className="mt-3">
            {shouldShowBtn && (
              <NavLink
                className={({ isActive }) =>
                  "text-aellaBlue bg-[#fffcf4] rounded-[4px] py-1 px-3 text-center mx-4"
                }
                to={ROUTES.UPGRADE_ACCOUNT}
              >
                <span className="text-[16px] lg:leading-[17px] font-[300]">
                  Upgrade Account
                </span>
              </NavLink>
            )}
          </div> */}
          <div className="px-5 pt-[28px] pb-2.5 text-[14px] tracking-[1px] lg:leading-[17px] font-[100]">
            {merchantDetails?.attributes?.registeredDocument?.registration_type}
          </div>
          <Sidebar />
          {merchantCntxtStatus === "1" && (
            <ul
              className={`flex flex-col absolute w-full left-0 bottom-[100px]`}
            >
              <p className="text-[14px] text-[#F7F8F8] lg:leading-[17px] tracking-[2.2px] pt-[10px]   font-[300] px-5 ">
                OTHERS
              </p>
              {/* PROFILE SETTINGS */}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? `border-l py-2 px-5 flex mb-3 mt-2.5 cursor-pointer bg-[#1941A4] shadow-inner-[2px 0px 0px] items-center`
                      : `py-2 px-5 flex w-full mb-3 mt-2.5 opacity-75 cursor-pointer items-center`
                  }
                  to={ROUTES.PROFILE_SETTING}
                >
                  <SettingsIcon />
                  <span className="ml-4 text-[16px] lg:leading-[17px] font-[300]">
                    Settings
                  </span>
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </div>
      {smallScreenSideBar && (
        <div className="left-0 top-0 z-30 fixed overflow-y-auto bg-aellaBlue lg:hidden flex flex-col w-[55vw] py-6 h-screen text-white transition ease-in-out duration-300">
          <div className="flex justify-between items-center px-5 pb-5 border-b border-white border-opacity-30">
            <div className="flex flex-col">
              <Link to={ROUTES.HOME}>
                <img src={Logo} alt="Aella Logo" />
              </Link>

              <p className="text-[14px] text-[#F7F8F8] lg:leading-[17px] tracking-[2.2px] pt-[10px] font-[300]">
                MERCHANT
              </p>
            </div>
            <div onClick={toggleSidebar}>
              <CloseIcon />
            </div>
          </div>

          {/* <div
            onClick={() => {
              window.location.reload();
            }}
            className="mt-3"
          >
            {shouldShowBtn && (
              <NavLink
                className={({ isActive }) =>
                  "text-aellaBlue bg-[#fffcf4] rounded-[4px] py-1 px-3 text-center mx-4"
                }
                to={ROUTES.UPGRADE_ACCOUNT}
              >
                <span className="text-[16px] lg:leading-[17px] font-[300]">
                  Upgrade Account
                </span>
              </NavLink>
            )}
          </div> */}
          <div className="px-5 pt-[28px] pb-2.5 text-[14px] tracking-[1px] lg:leading-[17px] font-[100] uppercase">
            {merchantDetails?.attributes?.registeredDocument?.registration_type}
          </div>
          <Sidebar />
          {merchantCntxtStatus === "1" && (
            <ul className={`flex flex-col mt-10 w-full left-0 bottom-[100px]`}>
              <p className="text-[14px] text-[#F7F8F8] lg:leading-[17px] tracking-[2.2px] pt-[10px]   font-[300] px-5 ">
                OTHERS
              </p>
              {/* PROFILE SETTINGS */}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? `border-l py-2 px-5 flex mb-3 mt-2.5 cursor-pointer bg-[#1941A4] shadow-inner-[2px 0px 0px] items-center`
                      : `py-2 px-5 flex w-full mb-3 mt-2.5 opacity-75 cursor-pointer items-center`
                  }
                  to={ROUTES.PROFILE_SETTING}
                >
                  <SettingsIcon />
                  <span className="ml-4 text-[16px] lg:leading-[17px] font-[300]">
                    Settings
                  </span>
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      )}
    </>
  );
};
export default SidebarNav;
