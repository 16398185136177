import moment from "moment";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllTeams = async ({ queryKey }: { queryKey: any }) => {
  const { page, size, startDate, endDate } = queryKey[1];

  let queryParams = `?page=${page}&per_page=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&start_date=${moment(startDate).format(
      "YYYY-MM-DD"
    )}`;
  }

  if (endDate) {
    queryParams = `${queryParams}&end_date=${moment(endDate).format(
      "YYYY-MM-DD"
    )}`;
  }

  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.TEAM_MEMBER}${queryParams}`
  );
  return res;
};

export const useAllTeams = ({
  page,
  size,
  endDate,
  startDate,
}: {
  page: string | number | undefined;
  size: string | number | undefined;
  endDate?: Date | null;
  startDate?: Date | null;
}) => {
  return useQuery(
    ["getAllTeamMembers", { page, size, startDate, endDate }],
    getAllTeams,
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};
